// material theme colors
// The theme primary color
$mdc-theme-primary: #344955;
$mdc-theme-primary-dark: #0b222c;
$mdc-theme-primary-light: #5f7481;
// text color on top of a primary background
$mdc-theme-on-primary: #ffffff;
// The theme secondary color
$mdc-theme-secondary: #f9aa33;
$mdc-theme-secondary-dark: #c17b00;
$mdc-theme-secondary-light: #ffdc65;
// text color on top a secondary background
$mdc-theme-on-secondary: #000000;
// The theme surface color
$mdc-theme-surface: #fffbfa;
//text color on top of a surface background
$mdc-theme-on-surface: #000000;
// The theme background color
$mdc-theme-background: #ffffff;
// text color on top of theme background
$mdc-theme-on-background: #000000;

// material component style imports
@import "@material/button/mdc-button";
@import "@material/icon-button/mdc-icon-button";
@import "@material/layout-grid/mdc-layout-grid";
@import "@material/card/mdc-card";
@import "@material/typography/mdc-typography";
@import "@material/dialog/mdc-dialog";
@import "@material/switch/mdc-switch";
@import "@material/slider/mdc-slider";
@import "@material/textfield/mdc-text-field";
@import "@material/textfield/icon/mdc-text-field-icon";
@import "@material/list/mdc-list";
@import "@material/menu-surface/mdc-menu-surface";
@import "@material/menu/mdc-menu";
@import "@material/select/mdc-select";

@import "./homepage.scss";

// app styles
* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}
body {
  margin: 0;
}
i.mdc-text-field__icon:focus {
  outline-width: 0;
}
.test {
  position: relative;
}
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 8px 0;
  background-color: #f9aa33;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #000;
  font-weight: bold;
  font-size: 16px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}
.ribbon-top-left span {
  right: -25px;
  top: 45px;
  transform: rotate(-45deg);
}
.top-app-bar {
  align-items: center;
  background-color: $mdc-theme-primary;
  display: flex;
  flex-wrap: nowrap;
  height: 64px;
  max-height: 64px;
  color: white;
  padding: 8px 12px;
  position: fixed;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4;
}
.top-app-bar__section {
  display: inline-flex;
}
.top-app-bar__section--align-start {
  justify-content: flex-start;
  flex-grow: 1;
  max-width: 100%;
}
.top-app-bar__section--align-end {
  justify-content: flex-end;
  align-items: center;
}
.top-app-bar__language-toggler {
  max-height: 24px;
  margin: 12px 0 12px 12px;
}
.top-app-bar__title {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  padding-left: 20px;
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}
.content {
  position: absolute;
  top: 64px;
  width: 100%;
}
.contents {
  border-bottom: 1px dashed grey;
  cursor: pointer;
  padding: 8px 0;
}
.sidebar {
  background-color: white;
  border: 1px solid $mdc-theme-primary;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  max-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  max-width: 250px;
  min-width: 250px;
  overflow-y: auto;
  padding: 8px;
  position: absolute;
  transform: scale(0, 1);
  transform-origin: top left;
  -webkit-box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.75);
  box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.75);
  z-index: -1;
  /* the transition property is set under router.js */
}
.sidebar__close {
  width: 100%;
}
.sidebar__content {
  font-size: 0.9em;
  max-height: 100vh;
  overflow-y: auto;
  padding: 8px;
}
.mdc-dialog {
  //change with/height via mixin
  @include mdc-dialog-min-width(80vw);
}
//credits: https://css-tricks.com/fluid-width-video/
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.monthchips {
  width: 100%;
}
.mdc-chip-set {
  border-bottom: 1px dotted grey;
  display: flex;
  flex-wrap: wrap;
  max-height: 170px;
  min-width: 100%;
  overflow-y: auto;
  padding: 8px;
}
.chip {
  background-color: #e0dddc;
  border-radius: 16px;
  cursor: pointer;
  font-size: 11px;
  margin: 4px 2px;
  padding: 8px;
  transition: background-color, 0.2s ease-out;
}
.chip--selected {
  background-color: #f9aa33;
}
.layers {
  max-height: calc(100vh - 270px);
  max-height: calc(var(--vh, 1vh) * 100 - 270px);
  padding: 0 8px;
  overflow: hidden;
  overflow-y: auto;
}

/* Viewer Section */
.search {
  @include mdc-text-field-fill-color(rgba(249, 170, 51, 0.9));
  @include mdc-text-field-shape-radius(4px);
  @include mdc-text-field-bottom-line-color(white);
  @include mdc-text-field-hover-bottom-line-color(white);
  @include mdc-text-field-line-ripple-color(white);
  @include mdc-text-field-height(40px);
  display: none;
  width: 100%;
  max-width: 500px;
}
#map {
  background-size: 20px 20px;
  background-image: linear-gradient(to right, grey 1px, transparent 1px),
    linear-gradient(to bottom, grey 1px, transparent 1px);
  height: calc(100vh - 64px);
  height: calc(var(--vh, 1vh) * 100 - 64px);
  width: 100vw;
}
@keyframes basemap {
  0%,
  100% {
    transform: translateX(0);
  }
  33% {
    transform: translateX(-2px);
  }
  66% {
    transform: translateX(2px);
  }
}
.animate {
  animation: basemap 0.3s 1 forwards;
}
.basemapControl {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}
.basemap {
  box-shadow: 0 2px 4px 0 #4a4a4a;
  cursor: pointer;
  width: 56px;
  height: 56px;
  border: 2px solid $mdc-theme-secondary;
}
.basemapControl__title {
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 11px;
  text-align: center;
}
.vhmControl {
  align-items: center;
  box-shadow: 0 2px 4px 0 #4a4a4a;
  border: 2px solid $mdc-theme-secondary;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 56px;
  position: absolute;
  right: 0.5em;
  top: 80px;
  width: 56px;
}
.vhmControl__title {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  padding: 1px 4px;
  text-align: center;
  width: 100%;
}
.vhmControl__info {
  width: 15px;
  height: 15px;
}
.vhmControl__waiting {
  color: dimgray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.vhm__text {
  align-items: center;
  color: $mdc-theme-primary;
  display: flex;
  font-size: 12px !important;
  font-weight: bold;
  height: 100%;
}
.viewerControl__title,
.viewerControl__controls {
  align-items: center;
  box-shadow: 0 2px 4px 0 #4a4a4a;
  display: flex;
  flex-wrap: wrap;
  min-width: 245px;
  max-width: 245px;
  position: absolute;
}
.viewerControl__title-text {
  flex-grow: 1;
  font-size: 17px;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.viewerControl__controls-control {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.viewerControl__controls-control:first-child {
  padding-top: 8px;
}
.viewerControl__controls-control:not(:last-child) {
  margin-bottom: 12px;
  border-bottom: 1px dotted grey;
}
.viewerControl__title {
  background-color: $mdc-theme-secondary;
  cursor: pointer;
  left: 0em;
  top: 0em;
  padding: 8px;
}
.viewerControl__controls {
  background-color: rgba(255, 255, 255, 0.9);
  top: 2.5em;
  left: 0;
  max-height: calc(100vh - 105px);
  max-height: calc(var(--vh, 1vh) * 100 - 105px);
  transform: scale(1, 1);
  transform-origin: top left;
  transition: transform, opacity, 0.3s;
}
.viewerControl__helpertext,
.viewerControl__yearinfo {
  font-size: 13px;
}
.viewerControl__helpertext {
  padding: 16px 8px 0 8px;
}
.viewerControl__yearinfo {
  padding: 16px 8px 4px 8px;
}
.title__arrow {
  transition: transform 0.3s;
}
.viewerControl__dropdown {
  width: 100%;
}
.viewerControl__layerselect {
  width: 100%;
  min-width: 100%;
}
.layer-button {
  width: 30px;
  height: 30px;
  padding: 0;
}
.slidercontainer {
  display: flex;
  align-items: center;
  padding: 0;
  flex-grow: 1;
}
.mdc-slider {
  margin-right: 12px;
}
.mdc-slider__thumb-container {
  height: auto;
}
.basemapControl:hover {
  background-color: none;
}
.ol-attribution {
  justify-content: space-between !important;
  min-width: 100%;
}
.ol-zoom {
  bottom: 2.1em !important;
  right: 0.5em !important;
  top: unset !important;
  left: unset !important;
}
.ol-zoom-in,
.ol-zoom-out,
.ol-rotate-reset {
  cursor: pointer !important;
}
.ol-rotate {
  bottom: 7em !important;
  top: unset !important;
}
.autocomplete {
  background-color: white;
  max-height: 400px;
  overflow-y: scroll;
  position: absolute;
  top: 56px;
  transform: scale(1, 0);
  transform-origin: top left;
  transition: transform, 0.3s;
}
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  max-width: 290px;
  min-width: 290px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}
.ol-popup-closer:after {
  content: "✖";
}
.ol-scale-line {
  background: none !important;
  position: unset !important;
  bottom: unset !important;
  left: unset !important;
}
.ol-scale-line-inner {
  border: 1.25px solid black !important;
  border-top: none !important;
  color: black !important;
  font-weight: bold !important;
}
#popup__completionmessage {
  font-size: 0.8em;
  padding: 8px 0 8px 0;
}
#popup__mandatorymessage {
  font-size: 0.8em;
  display: none;
  padding: 0 0 8px 0;
}
.popup__editform {
  padding: 8px;
}
.popup__attributetable,
.popup__formcontainer {
  margin-top: 12px;
  width: 100%;
}
.popup__attributetable {
  padding-bottom: 12px;
}
.popup__attributetable--td {
  font-size: 0.8em;
  padding: 4px;
}
.popup__attributetable--title {
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  height: 40px;
  padding: 4px;
  text-align: center;
}
.button__fullwidth {
  width: 100%;
}
.correctselect {
  padding-top: 8px;
}
.popup__radiocontainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  column-gap: 8px;
  row-gap: 2px;
}
.popup__checkboxcontainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  column-gap: 8px;
  row-gap: 8px;
}
.popup__checkboxcontainer_customreason {
  background-color: gainsboro;
  border: none;
  border-bottom: 1px dashed black;
}
.popup__checkboxcontainer_customreason:focus {
  outline: none;
}
.red {
  color: red;
}
.page__title {
  font-size: 3em;
  padding-bottom: 0;
}
/*
* position button animation
*/
.animatePositionButton {
  animation: pulse 1.5s alternate infinite;
  border: 3px solid red;
}
@keyframes pulse {
  0% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

/*
* popup tabs
*/
/* Style the tab */
.tab {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border: 1px solid #ccc;
  border-top: none;
  background-color: #f1f1f1;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #344955;
  color: #f9aa33;
  font-weight: bold;
}

/* Style the tab content */
.tabcontent {
  display: none;
  border: 1px solid #ccc;
  border-bottom: none;
  padding-bottom: 12px;
}

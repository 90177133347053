//
// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import "@material/density/variables";
@import "@material/theme/variables"; // for mdc-theme-prop-value

$mdc-button-height: 36px !default;
$mdc-button-horizontal-padding: 8px !default;
$mdc-button-contained-horizontal-padding: 16px !default;

$mdc-button-minimum-height: 24px !default;
$mdc-button-maximum-height: $mdc-button-height !default;
$mdc-button-density-scale: $mdc-density-default-scale !default;
$mdc-button-density-config: (
  height: (
    default: $mdc-button-height,
    maximum: $mdc-button-maximum-height,
    minimum: $mdc-button-minimum-height,
  ),
) !default;

$mdc-button-outlined-border-width: 1px !default;
$mdc-button-shape-radius: small !default;

$mdc-button-disabled-ink-color: rgba(mdc-theme-prop-value(on-surface), .37) !default;
